/* globals console */

/**
 * @description Loads the device capacity from local storage if set.
 * @returns {number} The device capacity. If one has not been set then 5000 KB is returned
 */
function getDeviceCapacity() {
	"use strict";
	if (localStorage.getItem("settings/deviceCapacity")) {
		return localStorage.getVariable("settings/deviceCapacity")*1;
	} else {
		// Never been calculated before. Lets do it now.
    return calculateDeviceCapacity();
	}
}

/**
 * @description Calculates grouped usage of disk space on the scanner.
 * @returns {{totalUsage: *, groups: {settings: number, data: number, transactions: number, security: number, other: number}, totalCapacity: number}}
 */
function getDeviceUsage() {
	"use strict";

	// To account for additional storage groups, they should be defined here.
	// storeTest is not a real group but must be accounted for when calculating the device capacity.
	var storageGroups = {
		'settings': 0,
		'data': 0,
		'transactions': 0,
		'security': 0,
		'other': 0,
		'capacities': 0,
		'storeTest': 0
	};

	var groupArray = Object.keys(storageGroups),
		totalUsage=0,
		itemUsage,
		item;

	for(item in localStorage)	{
		if(!localStorage.hasOwnProperty(item)) {
			continue;
		}

		// We have to try and catch to get the length. On a Windows scanner, a blank string as a value causes an error.
		try {
			// try and get the length of the item contents.
			itemUsage = (((localStorage[item].length + item.length)* 2) / 1024);
		} catch(e) {
            // if it fails we just get the item key size.
			itemUsage = item.length * 2 / 1024;
		}
		totalUsage+=itemUsage;

		var categorised = false;
		for (var o=0;o<groupArray.length;o++) {
			if (item.substr(0,groupArray[o].length) === groupArray[o]) {
				// in group
				storageGroups[groupArray[o]] += itemUsage;
				categorised = true;
			}
		}
		if (!categorised) {
			storageGroups.other += itemUsage;
		}
	}

	//for(var z=0; z<groupArray.length;z++) {
		//console.log(groupArray[z] + " has this much data: " + storageGroups[groupArray[z]] + " KB");
	//}

	//console.log("Total = " + totalUsage.toFixed(2) + " KB");
	return {
		totalUsage: totalUsage,
		groups: storageGroups,
		totalCapacity: getDeviceCapacity()
	};
}

function calculateDeviceCapacity() {
	"use strict";

	//Internal function to make storage Ids for saving to local storage.
	function makeStorageId() {
		return 'storeTest/xxxxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	var keepGoing = true;
	var repeat = function(str, x) { return new Array(x+1).join(str); };
	// bigString will be created. A big string is 512 characters, 1024 bytes or 1 KB.
	var bigString = repeat("x", 1024/2); // each JS character is 2 bytes
	while (keepGoing) {
		try {
			//Set item allowed here, as this is a test and errors are caught here.
			localStorage.setItem(makeStorageId(), bigString);
		} catch (e) {
			console.log("Calculation of device capacity hit. Maximum storage now measurable.");
			keepGoing = false;
		}
	}
	// Calculate size of data in local storage, and then clear the testing data.
	var totalUsage=0,
	itemUsage;
	for(var item in localStorage)	{
		if(!localStorage.hasOwnProperty(item)) {
			continue;
		}

		// We have to try and catch the length of the value of the item
		try {
			// try and get the length of the item contents.
			itemUsage = (((localStorage[item].length + item.length)* 2) / 1024);
		} catch (e) {
			// if it fails we just get the item key size.
			itemUsage = item.length * 2 / 1024;
		}
		totalUsage+=itemUsage;
		if (item.substr(0,9) === "storeTest") {
			localStorage.removeItem(item);
		}
	}
	totalUsage = totalUsage.toFixed(0) - 1;
	console.log("Total Capacity: " + totalUsage);
    localStorage.setVariable("settings/deviceCapacity",totalUsage, false);
	return totalUsage;
}

function getMaxTransactionCount() {
	"use strict";
	return parseInt(localStorage.getVariable("settings/maxTransactions"));
}

function getTransactionQuota() {
	"use strict";
	//Each transaction is estimated at 0.9KB. We need to return a KB value.
	var maxTransactions = parseInt(localStorage.getVariable("settings/maxTransactions")) + 500;
	return maxTransactions / 0.9;
}

function willDownloadExceedAllowance(downloadObject, originalObjectSize) {
	"use strict";

	var transactionQuota = getTransactionQuota(),
		deviceUsage = getDeviceUsage(),
		totalUsed=0;

	console.log(deviceUsage.totalCapacity);
	// Calculate device usage minus transactions usage.
	// Do we need more data groups. Maybe for different download types in future.
	for(var group in deviceUsage.groups) {
		if (!deviceUsage.groups.hasOwnProperty(group)) continue;
		// We must ignore any transactions here, and any residual data in the capacity calculation.
		// LS can have a delay in the read and write. By the time we write storeTest will all be deleted.
		if(group !== 'transactions' && group !== 'storeTest') {
			console.log("counted: " + deviceUsage.groups[group]);
			totalUsed+=deviceUsage.groups[group];
		}
	}
	var spaceIncrease = 0;

	//Calculate size of the new download / updated object
	var objectSize = getObjectSize(downloadObject);
	//If this is an update then we started with an original object. Lets get its value too
	if (originalObjectSize) {
		spaceIncrease = objectSize - originalObjectSize;
	} else {
		spaceIncrease = objectSize;
	}
	//var totalDesiredUsage = objectSize + totalUsed;
	var totalDesiredUsage = totalUsed + spaceIncrease;
	//console.log("Statistics:");
	//console.log("New Object Size: ", objectSize);
	//console.log("An Original size: ", originalObjectSize);
	//console.log("Difference: ", spaceIncrease);
	//console.log("Required Space: ", totalDesiredUsage);
	//console.log("Transaction Quota", transactionQuota);
	//console.log("Device Capacity", deviceUsage.totalCapacity);
	console.log("Will download exceed capacity: " + (totalDesiredUsage + transactionQuota > deviceUsage.totalCapacity));

	//if usage plus transaction quota exceeds device capacity then return true, as we are going to exceed if we want to store max transactions.
  return (totalDesiredUsage + transactionQuota > deviceUsage.totalCapacity);
}

/**
 *
 * @param object The object to measure for its eventual size in LocalStorage.
 * @returns {number} Size of object in KB
 */
function getObjectSize(object) {
	"use strict";
	return (JSON.stringify(object).length * 2) / 1024; //Number

}